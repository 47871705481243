@use '../../App.scss';

.control-panel {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.input-error {
    color: App.$invalid-color;
}

.copied-word {
    color: App.$emphasis-color;
}
