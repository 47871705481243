@use '../../App.scss';

// Game board container
.game-board {
    flex: 1;
    display: flex;
    flex-direction: column;
}

// Styling for the game board
.game-board-top {
    margin-top: 5vh;
    margin-bottom: auto;
}

// Styling for the game board
.game-board-bottom {
    margin-top: auto;
    margin-bottom: 5vh;
}

// Game error styles
.game-error {
    padding: 10px;
    text-align: center;
    font-size: 20px;
}