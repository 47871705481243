@use '../../App.scss';

.keyboard {
    
    .keyboard-row {
        display: flex;
        justify-content: center;
        align-items: center;

        max-width: 100%;
    }
    
    .keyboard-key {
        flex: 1;

        height: 50px;
        min-width: 12px;
        max-width: 35px;

        padding: 0 10px;
        margin: 3px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: bold;
        text-transform: uppercase;
        
        background-color: App.$unknown-letter-color;
        border-radius: 2px;
        
        cursor: pointer;
    }
}

// Class that applies the key reveal animation
.reveal-key {
    animation: key-animation 0.6s;
}

@keyframes key-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}