@use '../../App.scss';

// Default game cell styles
.game-cell {

    aspect-ratio: 1/1;
    width: 50px;
    min-height: 20px;
    margin: 3px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 100%;
    font-weight: bold;
    text-transform: uppercase;

    border: 2px solid App.$background-surface-color;
    border-radius: 2px;

    transition: border-color 0.25s, background-color 0.25s;
}

// Adjust cell sizes on mobile devices
@media (max-width: 576px) {
    .game-cell {
        margin: 2px;
    }
}

@media (max-width: 440px) {
    .game-cell {
        margin: 1.5px;
    }
}

// Cell is filled with a letter, but not submitted as a guess
.filled-cell {
    color: App.$primary-color;
    border: 2px solid App.$primary-color;
    animation: scale-animation 0.2s;
}

// Cell is next in line to receive keyboard input
.target-cell {
    border: 2px solid App.$emphasis-color;
}

// Occurs when the cell is revealed via 'Enter' key
.reveal-cell {
    animation: reveal-animation 0.6s;
}

// Occurs when the cell is a part of the winning word
.winning-cell {
    position: relative;
    animation: win-animation 0.5s;
}

@keyframes scale-animation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes reveal-animation {

    0%,
    100% {
        transform: rotateX(0deg);
    }

    10%,
    90% {
        transform: rotateX(18deg);
    }

    20%,
    80% {
        transform: rotateX(36deg);
    }

    30%,
    70% {
        transform: rotateX(54deg);
    }

    40%,
    60% {
        transform: rotateX(85deg);
    }

    50% {
        transform: rotateX(90deg);
    }
}

@keyframes win-animation {
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }
}