// Text colors
$primary-color: lightgray;
$emphasis-color: white;
$low-emphasis-color: rgb(150, 150, 150);
$correct-color: #538d4e;
$invalid-color: #f37950;

// Background colors
$background-color: #121212;
$background-surface-color: #414141;
$background-modal-color: $background-color;
$background-overlay-color: rgba($background-color, 0.2);

// Letter colors
$correct-letter-color: $correct-color;
$contains-letter-color: #b59f3b;
$invalid-letter-color: $background-surface-color;
$unknown-letter-color: rgb(125, 125, 125);

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body {
  height: 100%;
}

// Default body styles
body {
  user-select: none;

  background-color: $background-color;
  color: $primary-color;

  overflow-wrap: break-word;
}

.app-body {
  min-height: 100vh;

  display: flex;
  flex-direction: column;
}

.app-content {
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;
}

// Styling for text-based buttons
.jordle-button {
  background-color: $background-surface-color;
  color: $primary-color;
  border: 2px solid $primary-color;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 5px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: $primary-color;
    color: $background-surface-color;
  }
}

// Styling for icons (Back arrows, etc.)
.jordle-icon {
  background: none;
  border: none;
  border-radius: 5px;
  color: $emphasis-color;
  padding: 5px 8px;
  margin: 3px 0px;
  transition: all 0.2s;

  &:hover {
    background-color: $primary-color;
    color: $background-surface-color;
  }
}

// Override bootstrap modal styles
.modal-content {
  background-color: $background-modal-color;
}

// Letter is in the same position as the solution
.correct-letter {
  background-color: $correct-letter-color !important;
  border-color: $correct-letter-color !important;
}

// Letter makes up the solution
.contains-letter {
  background-color: $contains-letter-color !important;
  border-color: $contains-letter-color !important;
}

// Letter does not make up the solution
.invalid-letter {
  background-color: $invalid-letter-color !important;
  border-color: $invalid-letter-color !important;
}